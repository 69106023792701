import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import Header from "../components/header";
import ViewDetailsResult from "../components/viewDetailsResult";
import Footer from "../components/footer";
import SeO from "../components/SEO";
import NotFoundPage from "./404";

import NotFound from "../components/notfound";
import Loader from "../images/loader.gif";
import settings from "../components/settings.json";

const DetailsPage = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageTitle, setPageTitle] = useState();

  useEffect(() => {
    if (props.data.serverData.data.searchByCatOrBusinessSlug.length) {
      setData(props.data.serverData.data.searchByCatOrBusinessSlug[0]);
      setPageTitle(
        props.data.serverData.data.searchByCatOrBusinessSlug[0].name
      );
      setLoading(true);
    }
  }, [data]);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <div>
      <SeO
        title={pageTitle}
        description={
          props.data.serverData.data.searchByCatOrBusinessSlug[0].message
        }
      />
      <Header />
      {loading ? (
        <p style={{ display: `block`, marginTop: "80px" }}>
          <img
            height="100px"
            alt="Loader"
            width="100px"
            style={{ margin: "30px" }}
            src={Loader}
          />
        </p>
      ) : data ? (
        <ViewDetailsResult
          data={data}
          city={props.data?.city}
          state={props.data?.stateCode}
        />
      ) : (
        <NotFound />
      )}
      <Footer />
    </div>
  );
};

const details = ({ pageContext, serverData }) => {
  return (
    <Router>
      <DetailsPage
        path="/details/:slug"
        context={pageContext}
        data={serverData}
      />
      <DetailsPage
        path="/details/:slug/:city-state"
        context={pageContext}
        data={serverData}
      />
      <NotFoundPage path="/details/*" />
    </Router>
  );
};

export default details;

export async function getServerData(props) {
  const business = props.params["*"]?.split("/")[0];
  const cityState = props.params["*"]?.split("/")[1];
  const city = cityState?.split("-")[0];
  const state = cityState?.split("-")[1];

  try {
    if (city && state) {
      const res = await fetch(settings.apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `{
            searchByCatOrBusinessSlug(slug: "${business}", location: "${city}", state_code: "${state}"){
              id
              name
              phone
              address
              city
              state
              zip
              message
              referred_by
              category
              company_logo
              latitude
              longitude
              website
              facebook
              instagram
              youtube
             
              twitter
              linkedin
              audio
              video
              description
              service_frequency
              days_open
              email
              show_address
              show_ad
              company_coupon
              company_ad
              review_link
              spotlight_link
              images{
                  id
                  path
              }
            }
          }`,
        }),
      });
      if (!res.ok) {
        throw new Error(`Response failed`);
      }
      const data = {
        serverData: await res.json(),
        slug: business,
        city: city,
        stateCode: state,
      };

      return {
        props: data,
      };
    } else {
      const res = await fetch(settings.apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `{
            searchByCatOrBusinessSlug(slug: "${business}"){
              id
              name
              phone
              address
              city
              state
              zip
              message
              referred_by
              category
              company_logo
              latitude
              longitude
            
              website
              facebook
              instagram
              youtube
              twitter
              linkedin
              audio
              video
              description
              service_frequency
              days_open
              email
              show_address
              show_ad
              company_coupon
              company_ad
              review_link
              spotlight_link
              images{
                  id
                  path
              }
            }
          }`,
        }),
      });
      if (!res.ok) {
        throw new Error(`Response failed`);
      }

      const data = {
        serverData: await res.json(),
        slug: business,
      };

      return {
        props: data,
      };
    }
  } catch (err) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
