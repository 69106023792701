/* eslint-disable */
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ReturnAddOnFields from "./addOnFields";

const axios = require("axios");

export default function ViewDetailsResult({ data, city, state }) {
  const [imageArray, setImageArray] = useState([]);
  const [businessAddress, setBusinessAddress] = useState("");

  const setImages = (images) => {
    if (images.length !== 0) {
      let newArray = [];
      images.map((img) => {
        let imagePath = img.path;
        newArray.push({
          original: imagePath,
          thumbnail: imagePath,
        });
        setImageArray(newArray);
      });
    }
  };

  const GetMapUrl = (address) => {
    if (city && state) {
      let fromCity = `${city}, ${state}`;

      return (
        "https://www.google.com/maps/dir/" +
        encodeURI(fromCity) +
        "/" +
        encodeURI(address)
      );
    } else {
      return "https://www.google.com/maps/dir/" + "/" + encodeURI(address);
    }
  };

  useEffect(() => {
    let alldata = data;
    if (alldata?.address) {
      var businessAdd =
        (alldata.address ? alldata.address.trim() + ", " : "") +
        (alldata.city ? alldata.city.trim() : "") +
        (alldata.state ? ", " + alldata.state : "")+
        (alldata.zip ? " " + alldata.zip : "");
      businessAdd = businessAdd.trim();
      setBusinessAddress(businessAdd);
      setImages(data.images);
    }
  }, [data]);
 
  return (
    <main>
      <section className="listing-details py-md-4">
        <div
          className={imageArray.length === 0 && "container"}
          style={{ margin: `100px auto 0` }}
        >
          <div className="row align-items-start justify-content-center">
            {imageArray.length > 0 && (
              <div className="col-md-6 py-4 px-3">
                <ImageGallery items={imageArray} />
              </div>
            )}
            <div
              className={
                "py-md-4 px-3 " + (imageArray.length ? "col-md-6" : "col-md-12")
              }
            >
              <div className="category-card">
                <div className="company-details d-flex align-items-center justify-content-start">
                  <div className="company-logo mr-lg-4">
                    {data.company_logo?.length > 0 && (
                      <img
                        height="100"
                        width="100"
                        src={data.company_logo}
                        alt={data.name}
                        className="h-auto mw-100"
                      />
                    )}
                  </div>
                  <div className="company-about">
                    <h1 className="company-name mb-lg-3">{data.name}</h1>
                    {businessAddress.length && data.show_address === 1 ? (
                      <a
                        href={GetMapUrl(businessAddress)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="company-address"
                      >
                        {businessAddress}
                      </a>
                    ):(
                      ""
                      // <div className="company-address">{data.city}, {data.state} {data.zip}</div>
                    )}
                    {data.phone ? (
                      <h2 className="company-address">
                        Contact No :{" "}
                        <a
                          href={"tel:" + data.phone}
                          className="company-address col-md-8"
                        >
                          {data.phone}
                        </a>
                      </h2>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="company-details">
                  <p className="">{data.description}</p>
                  <p className="">{data.message}</p>
                </div>
                <div className="company-footer">
                  <ul className="services">
                    {data.service_frequency ? (
                      <li>{data.service_frequency}</li>
                    ) : (
                      ""
                    )}
                    {data.days_open ? <li>{data.days_open}</li> : ""}
                  </ul>
                </div>
                <div className="d-flex flex-wrap footer-links pt-3 pt-md-0 pb-5">
                  <ReturnAddOnFields data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
